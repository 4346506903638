import React, {Component} from 'react';
import {DialogActions, Button, Dialog, DialogContent, DialogTitle} from '@material-ui/core';

class AlertDialog extends Component {

    clickedPositive = () => {
        this.props.action(true);
    };

    clickedNegative = () => {
        this.props.action(false);
    };

    size = () => {
        return this.props.size || "sm";
    };

    render() {
        return (
            <div>
                <Dialog open={this.props.open} fullWidth={true} maxWidth={this.size()}>
                    <DialogTitle>{this.props.title}</DialogTitle>
                    <DialogContent>
                        {this.props.content && this.props.content()}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.clickedNegative} color="primary">
                            {this.props.negative}
                        </Button>
                        <Button onClick={this.clickedPositive} color="primary" autoFocus>
                            {this.props.positive}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

}


export default AlertDialog;