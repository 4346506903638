import React from "react";
import {CircularProgress} from '@material-ui/core';

const Loader = ({loaded}) => (
    <div style={{
        display: loaded ? "none" : "flex",
        width: '100vw',
        justifyContent: 'center',
        marginTop: '20vh'
    }}>
        <CircularProgress color="primary" size={100} thickness={2}/>
    </div>
);

export default Loader;