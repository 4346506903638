import * as KJUR from "jsrsasign";
export const getHaversineDistance = (firstLocation, secondLocation) => {
    const earthRadius = 6371000; // metres

    const diffLat = (secondLocation.lat - firstLocation.lat) * Math.PI / 180;
    const diffLng = (secondLocation.lng - firstLocation.lng) * Math.PI / 180;

    const arc = Math.cos(
            firstLocation.lat * Math.PI / 180) * Math.cos(secondLocation.lat * Math.PI / 180)
        * Math.sin(diffLng / 2) * Math.sin(diffLng / 2)
        + Math.sin(diffLat / 2) * Math.sin(diffLat / 2);
    const line = 2 * Math.atan2(Math.sqrt(arc), Math.sqrt(1 - arc));

    return earthRadius * line;
};

export const getLocation = (cb) => {
    navigator.geolocation.getCurrentPosition(cb, (e) => {
        console.log(e);
    });
};

export const round1 = (n) => {
    return parseFloat(Math.round(n * 10) / 10).toFixed(1);
};

export const round3 = (n) => {
    return parseFloat(Math.round(n * 100) / 100).toFixed(2);
};

export const round4 = (n) => {
    return parseFloat(Math.round(n * 1000) / 1000).toFixed(3);
};

export const rn = () => {
    return (new Date()).getTime() / 1000
};

export const access_levels = ["", "Super User", "Admin", "User", "Guest"];

export const getAccessLevel = (access) => {
    return access_levels[access] || "Unknown";
};

export const getPairingCode = () => {
    return localStorage.getItem("pairing_code");
};

export const getAppName = () => {
    return localStorage.getItem("app_name");
};

export const getJwt = () => {
    let jwt = localStorage.getItem("vl-jwt");
    if (jwt == null)
        return sessionStorage.getItem("vl-jwt");
    return jwt;
};

export const getJwtObj = () => {
    let jwt = getJwt();
    if (jwt == null)
        return null;
    return KJUR.jws.JWS.readSafeJSONString(KJUR.b64utoutf8(jwt.split(".")[1]));
};

export const getUsername = () => {
    let jwt = getJwtObj();
    if (jwt == null)
        return null;
    return jwt["username"];
};

export const getUserAccessLevel = () => {
    let jwt = getJwtObj();
    if (jwt == null)
        return null;
    return jwt["access_level"];
};

export const isAdmin = () => {
    return getUserAccessLevel() <= 2;
};

export const isCommunity = () => {
    return window.location.href.indexOf("matrix.vision-link.co.uk") !== -1 || window.location.href.indexOf("matrix.localhost:3000") !== -1;
};

export const isSubAccount = () => {
    return localStorage.getItem("vl-isSubAccount") === true || localStorage.getItem("vl-isSubAccount") === "true";
};

export const handleLogout = (result) => {
    if (!result.success) {
        if (result.error == "Invalid Login") {
            let original = window.location.href;
            original = encodeURIComponent(original);
            window.open("/login?redirect=" + original, "_self");
        }
        else if (result.error == "Invalid pairing code") {
            let original = window.location.href;
            original = encodeURIComponent(original);
            window.open("/pair?redirect=" + original, "_self");
        }
    }
};

export const logout = () => {
    localStorage.removeItem("vl-jwt");
    sessionStorage.removeItem("vl-jwt");
    window.open("/login", "_self");
};

export const unpair = () => {
    localStorage.removeItem("pairing_code");
    localStorage.removeItem("app_name");
    window.open("/pair", "_self");
};

export const GET = (key, def) => {
    //gets the url param value for a key
    let search = window.location.search.substr(1);

    let parts = search.split("&");
    for (let i = 0; i < parts.length; i++) {
        let keyvalue = parts[i];
        if (keyvalue.indexOf(key + "=") === 0) {
            //get the value
            return keyvalue.substr((key + "=").length);
        }
    }
    return def;
};

export const getHistory = () => {
    let links = localStorage.getItem("history2");
    try {
        links = JSON.parse(links);
        return links || [];
    } catch (e) {
        //default
        return [];
    }
};

export const logHistory = (title, link) => {
    let datum = {title, link};
    let history = getHistory();
    //check contains
    history = history.filter(h => h.link != link);
    if (history.length > 9) {
        history = history.slice(0, 9);
    }
    history = [datum].concat(history);
    localStorage.setItem("history2", JSON.stringify(history));
};

export const getGsmIcon = (signal) => {
    let gsm = 0;
    if (signal > 1) {
        gsm = 1;
    }
    if (signal > 6) {
        gsm = 2;
    }
    if (signal > 10) {
        gsm = 3;
    }
    if (signal > 15) {
        gsm = 4;
    }
    if (signal > 20 && signal < 99) {
        gsm = 5;
    }

    return "gsm_signal_" + gsm + "";
}

export const download = (url) => {
    return "https://api.vision-link.co.uk/api3/systems/downloadImage?url=" + encodeURIComponent(url);
};

export const isIE = () => {
    let ua = window.navigator.userAgent;

    let msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    let trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        let rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }
    return false;
};

export const safely = (task) => {
    try {
        task()
    } catch (e) {
        console.log(e);
    }
};

export const compare = (a, b) => {
    if (a == b)
        return 0;
    return a < b ? -1 : 1;
};


export const getQuery = (key, def) => {
    //gets the url param value for a key
    const search = window.location.search.substr(1);

    const parts = search.split("&");
    for (let i = 0; i < parts.length; i++) {
        const keyvalue = parts[i];
        if (keyvalue.indexOf(key + "=") == 0) {
            //get the value
            const value = keyvalue.substr((key + "=").length);
            return decodeURIComponent(value);
        }
    }
    return def;
};

export const moneyise = (x) => {
    return "£" + commaise(x);
};

export const commaise = x => {
    if (x == null) return "";
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const sum = (arr) => {
    return arr.reduce((a, b) => a + b, 0);
};

export const formatSeconds = (totalDownSeconds) => {
    const totalDownInMinutes = Math.floor(totalDownSeconds / 60);
    const totalDownMinutes = totalDownInMinutes % 60;
    const totalDownHours = (totalDownInMinutes - totalDownMinutes) / 60;
    if (totalDownHours < 24)
        return totalDownHours + "h " + totalDownMinutes + "m";
    const totalDownLeftoverHours = totalDownHours % 24;
    const totalDownDays = (totalDownHours - totalDownLeftoverHours) / 24;
    return totalDownDays + "d " + totalDownLeftoverHours + "h " + totalDownMinutes + "m";
};

export const formatPence = (penceCostInt) => {
    const penceCost = penceCostInt.toString();
    if (penceCost.length <= 1) {
        return "£0.0" + penceCost;
    }
    if (penceCost.length <= 2) {
        return "£0." + penceCost;
    }
    const pence = penceCost.substr(penceCost.length - 2);
    const pounds = penceCost.substr(0, penceCost.length - 2);
    return moneyise(pounds) + "." + pence;
};

export const formatPercent = (fraction) => {
    if (isNaN(fraction)) {
        return "";
    }
    const percent = fraction * 100;
    const formatted = percent.toFixed(2);

    let [number, decimal] = formatted.toString().split(".");
    if (decimal == "00") {
        return number + "%";
    }
    if (decimal[1] == "0")
        decimal = decimal.substring(0, 1);

    return number + "." + decimal + "%";
};

export const goBack = () => {
    window.history.back();
};

export const getValue = (key, def) => {
    //gets the url param value for a key
    let search = window.location.search.substr(1);

    let parts = search.split("&");
    for (let i = 0; i < parts.length; i++) {
        let keyvalue = parts[i];
        if (keyvalue.indexOf(key + "=") === 0) {
            //get the value
            let value = keyvalue.substr((key + "=").length);
            return decodeURIComponent(value);
        }
    }
    return def;
};

export const alertLevelName = () => {
    const name = localStorage.alert_level_name;
    if (name === null || name === undefined || name === "") return "Alert";
    return name;
};

export const actConLevelName = () => {
    const name = localStorage.act_con_level_name;
    if (name === null || name === undefined || name === "") return "Act Con";
    return name;
};

export const warningLevelName = () => {
    const name = localStorage.warning_level_name;
    if (name === null || name === undefined || name === "") return "Warning";
    return name;
};