import React, {useState, useEffect, useRef} from 'react';
import AlertDialog from "./AlertDialog";

export const useAlert = () => {

    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [positive, setPositive] = useState("");
    const [negative, setNegative] = useState("");
    const [showing, setShowing] = useState(false);
    const [onClick, setOnClick] = useState(null);
    const [onDismiss, setOnDismiss] = useState(null);

    const textAlertContent = () => {
        return <p style={{textAlign: 'center'}}>{message}</p>;
    };

    const action = wasPos => {
        setShowing(false);
        if (wasPos && onClick) {
            onClick();
        }
        if (onDismiss) {
            onDismiss();
        }
    };

    const component = <AlertDialog title={title} content={textAlertContent} positive={positive} negative={negative} action={action} open={showing}/>;

    const show = (title, message, positive, negative, onClick, onDismiss) => {
        setTitle(title);
        setMessage(message);
        setPositive(positive);
        setNegative(negative);
        setOnClick(() => onClick);
        setOnDismiss(() => onDismiss);
        setShowing(true);
    };

    const showBasic = (text, onDismiss) => {
        show(text, "", "OK", "", null, onDismiss);
    };

    return [component, showBasic, show];
};

export const useFileUpload = (accept, onSelected) => {

    const formRef = useRef(null);

    const formChanged = () => {
        onSelected(formRef.current[0].files[0]);
    };

    const openPicker = () => {
        formRef.current[0].click();
    };

    const component = (
        <form onChange={formChanged} ref={formRef} style={{display: 'none'}}>
            <input type="file" id="file" accept={accept}/>
        </form>
    );

    return [component, openPicker];
};

export const useInterval = (interval) => {
    const [time, setTime] = useState(0);

    useEffect(() => {
        const i = setInterval(() => {
            setTime(t => t + 1);
        }, interval);
        return () => clearInterval(i);
    }, [interval]);

    return time;
};