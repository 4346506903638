import React, {Component} from "react";

import VLComponent from "./VLComponent";

class ErrorScreen extends VLComponent {

    constructor() {
        super();
        this.state = {loaded: false, events: []};
    }

    render() {
        return (
            <div>
                <p>Page not found</p>
                <a href="/">Go Home</a>
            </div>
        );
    }

}

export default ErrorScreen;