import Paper from "@material-ui/core/es/Paper/Paper";
import React from "react";
import * as API from "../api";
import ActionBar from "../components/ActionBar";
import Loader from "../components/Loader";
import VLButton from "../components/VLButton";
import * as Util from "../util";
import VLComponent from "./VLComponent";

export class VLScreen2 extends VLComponent {

    constructor() {
        super();
    }

    render() {
        return <div>
            <ActionBar title={this.props.title} hideBack={this.props.hideBack === true} showMenu={this.props.showMenu}/>
            {(this.props.loaded && this.props.error == null) && this.main()}
            {this.pageContent()}
        </div>;
    }

    main() {
        return <div id="content" style={{
            maxHeight: "calc(100vh - 64px)",
            padding: (this.props.contentPadding === undefined ? 24 : this.props.contentPadding),
            overflowY: "auto"
        }}>
            {this.props.children}
        </div>;
    }

    pageContent() {

        return [<Loader loaded={this.props.loaded}/>,
            <div style={{
                display: (this.props.error != null && this.props.loaded) ? "flex" : "none",
                justifyContent: "center",
                width: "100%",
                marginTop: 64
            }}>
                <Paper style={{padding: 64, textAlign: "center", borderRadius: 16}}>
                    <h2>Error</h2>
                    <p>{this.props.error}</p>
                    <VLButton title="Retry" onClick={this.props.retry}
                              class="top8"
                              style={{marginTop: 16, width: 400}}/>
                </Paper>
            </div>
        ];
    }

}

export const CenteredPaper = ({children}) => (
    <div id="content" style={{
        display: "flex",
        padding: 24,
        justifyContent: "center"
    }}>
        <Paper style={{
            borderRadius: 16,
            width: "20cm",
            maxWidth: "90vw",
            maxHeight: "calc(100vh - 116px)",
            overflow: "hidden"
        }}>
            {children}
        </Paper>
    </div>
);

const useBaseApi = (url, params, deps, func) => {
    const [loaded, setLoaded] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [data, setData] = React.useState({});

    const reload = () => {
        setLoaded(false);
        setError(null);
        func(url, params, result => {
            Util.handleLogout(result);
            if (result.success) {
                setData(result.result);
            } else {
                setError(result.error);
            }
            setLoaded(true);
        });
    };

    React.useEffect(reload, deps);

    return [loaded, error, data, reload];
};

export const useApi = (url, params, deps) => {
    return useBaseApi(url, params, deps, API.POST);
};

export const useAdminApi = (url, params, deps) => {
    return useBaseApi(url, params, deps, API.adminPOST);

};