import React from "react";

import ActionBar from "../components/ActionBar";
import VLButton from "../components/VLButton";
import VLComponent from "./VLComponent";
import {Paper, FormControlLabel, Checkbox} from "@material-ui/core";
import * as API from "../api";
import * as Util from "../util";
import Loader from "../components/Loader";

export default class LoginScreen extends VLComponent {

    constructor() {
        super();
        this.state = {loaded: true, rememberMe: true, error: ""}
    }

    render() {
        return (
            <div>
                <ActionBar title="Vision Link Admin Panel" hideAll={true}/>
                <div id="content" style={{
                    display: this.state.loaded ? "flex" : "none",
                    width: '100%',
                    justifyContent: 'center',
                    padding: 16,
                    height: 'calc(100vh - 70px)'
                }}>
                    <Paper style={{width: '15cm', padding: 32, borderRadius: 16, overflowY: 'auto',}}>
                        <div>
                            <img src="https://api.vision-link.co.uk/app_logo/VL.png"
                                 style={{width: 'calc(100% - 60px)', margin: 30}}/>
                        </div>

                        {this.warnIe()}
                        <p style={{textAlign: 'center'}}>Sign in below to access your account</p>
                        <br/>
                        {this.renderForm()}
                        <br/><br/>
                    </Paper>
                </div>
                <Loader loaded={this.state.loaded}/>
            </div>
        );
    }

    warnIe = () => {
        if (Util.isIE()) {
            return <p style={{textAlign: 'center', color: 'red'}}>Warning: You seem to be using Internet Explorer. We
                recommend you use Google Chrome for the best experience.</p>
        }
    };

    renderForm = () => {
        return (
            <div>

                <p className="nomargin"><b>Username</b></p>
                <input name="username" className="vl-input wide top8" placeholder="Username" value={this.state.username}
                       onChange={this.handleInputChange}/>
                <br/><br/>
                <p className="nomargin"><b>Password</b></p>
                <input name="password" type="password" className="vl-input wide top8" placeholder="Password"
                       value={this.state.password} onChange={this.handleInputChange}/>
                <p style={{color: 'red', textAlign: 'center'}}>{this.state.error}</p>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.state.rememberMe}
                            onChange={this.handleInputChange}
                            name="rememberMe"
                            color="primary"/>
                    }
                    label="Remember me"
                />
                <br/>

                <VLButton title="Login" className="wide top8" onClick={this.login}/>
            </div>
        )
    };

    login = () => {
        let username = this.state.username;
        let password = this.state.password;
        API.adminPOST("account/login", {username, password}, result => {
            if (result.success) {
                let token = result.result.token;
                if (this.state.rememberMe) {
                    localStorage.setItem("vl-jwt", token);
                } else {
                    sessionStorage.setItem("vl-jwt", token);
                }

                //get redirect
                let redirect = Util.GET("redirect", encodeURIComponent("/"));
                redirect = decodeURIComponent(redirect);
                window.open(redirect, "_self", "", true);
            } else {
                this.setState({error: result.error});
            }
        })
    };


}