import * as Util from "./util";

export const baseURL = "https://api.vision-link.co.uk/admin/api2/";

export const POST = (url, params, onDone) => {
    APIPOSTwithFile(url, params, onDone, null);
};

export const adminPOST = (url, params, onDone) => {
    APIPOSTwithFile(url, params, onDone, null, "https://api.vision-link.co.uk/admin/api2/");
};

function getDeviceId() {
    let id = localStorage.getItem("vl-id");
    if (id == null) {
        id = makeId(64);
        localStorage.setItem("vl-id", id);
    }
    return id;
}

function makeId(length) {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const APIPOSTwithFile = (url, params, onDone, addFiles, baseUrl) => {
    params.token = Util.getJwt();
    params.pairing_code = Util.getPairingCode();
    params.VLOS = 3;
    params.VLVERSION = 1;
    params.VLDEVICEID = getDeviceId();

    let cache = window.cache || {};

    //cache check
    let cacheKey = "cached-" + url + buildQuery(params);
    try {
        let raw = cache[cacheKey];
        if (raw != null) {
            //let json = JSON.parse(raw);
            // onDone(json);
        }
    }
    catch (e) {

    }

    let request = new XMLHttpRequest();
    request.onreadystatechange = function () {
        if (this.readyState === 4) {
            try {
                let json = JSON.parse("" + this.responseText);
                //cache it
                cache[cacheKey] = this.responseText;
                window.cache = cache;
                onDone(json);
            }
            catch (e) {
                console.log(e);
                onDone({
                    success: false,
                    errorTitle: "Could not connect",
                    error: "Please check your internet connection then try again.",
                    canRetry: true,
                    result: {}
                });
            }
        }
    };

    if (baseUrl == null)
        request.open("POST", "https://api.vision-link.co.uk/api3/" + url, true);
    else
        request.open("POST", baseUrl + url, true);

    //build param string
    let data = new FormData();
    for (let key in params) {
        data.append(key, params[key]);
    }

    if (addFiles != null) {
        addFiles(data);
    }

    request.send(data);
};

export const buildQuery = (params) => {
    let string = "";
    for (let key in params) {
        string = string + key + "=" + encodeURIComponent(params[key]) + "&";
    }
    return string;
};

export default this;