import TextField from "@material-ui/core/TextField";
import React, {useEffect, useState} from "react";
import * as API from "../api";
import FormView from "../components/FormView";
import {ManagedSortableTable} from "../components/SortableTable";

import VLButton from "../components/VLButton";
import * as Util from "../util";
import VLScreen from "./VLScreen";

export default class CustomerScreen extends VLScreen {

    constructor() {
        super();
        this.state = {loaded: false, error: null, users: [], groups: [], systems: [], sharedSystems: []};
    }

    componentDidMount() {
        this.fetch();
    }

    fetch = () => {
        API.adminPOST("customers/get", {customer_id: this.props.id}, result => {
            this.setState(result.result);
            this.setState(result.result.customer);
            this.setState({loaded: true});
        });
    };

    content() {
        return (
            <div>

                <div style={{display: "flex"}}>
                    <div style={{flex: 1}}>
                        <h2>{this.state.app_name}</h2>
                        <h4>Pairing Code{this.state.app_code}</h4>
                        <h4>Customer {this.state.app_customer_id}</h4>
                    </div>
                    <img src={this.state.image_url}/>
                </div>

                <h2>Users</h2>
                <div className="vl-border" style={{width: 600, marginBottom: 16, paddingRight: 16}}>
                    {this.newUserForm()}
                </div>
                <UsersList users={this.state.users} customerID={this.props.id}/>

                <h2>Groups</h2>
                <GroupsList groups={this.state.groups} onUpdate={this.handleGroupsUpdate}/>

                <div className="w3-row">
                    <div className="w3-half" style={{padding: "0 8px"}}>
                        <h2>Systems</h2>
                        <SortableSystemList systems={this.state.systems} customerID={this.props.id}/>
                        {this.renderAddSystem()}
                    </div>
                    <div className="w3-half" style={{padding: "0 8px"}}>
                        <h2>Shared Systems</h2>
                        {this.renderSharedSystems()}
                    </div>
                </div>

            </div>
        );
    }

    handleGroupsUpdate = newGroups => {
        const params = {
            group1: newGroups[0],
            group2: newGroups[1],
            group3: newGroups[2],
            customerID: this.props.id
        };
        API.POST("groups/set", params, result => {
            if (result.success) {
                alert("Saved");
            } else {
                alert(result.error);
            }
        });
    };

    newUserForm = () => {
        const levels = [
            {},
            {id: 2, label: "Admin"},
            {id: 3, label: "User"},
            {id: 4, label: "Guest"}
        ];

        const items = [
            {type: "title", text: "Add User"},
            {type: "text", name: "Name", value: this.state.nu_username, id: "nu_username"},
            {type: "text", name: "Email", value: this.state.nu_email, id: "nu_email"},
            {
                type: "text",
                name: "Password",
                value: this.state.nu_password,
                id: "nu_password"
            },
            {
                type: "dropdown2",
                name: "Access Level",
                value: this.state.nu_access_level,
                options: levels,
                id: "nu_access_level"
            },
            {type: "button", text: "Add User", onClick: this.saveNewUser}
        ];
        return <FormView changeEvent={this.handleInputChange} items={items}/>;
    };

    saveNewUser = () => {
        let data = {
            username: this.state.nu_username,
            email: this.state.nu_email,
            password: this.state.nu_password,
            access: this.state.nu_access_level,
            customer_id: this.props.id
        };
        API.adminPOST("users/add", data, result => {
            if (result.success) {
                this.setState({nu_username: "", nu_email: "", nu_password: ""});
                alert("User has been added");
                this.fetch();
            } else {
                alert(result.error);
            }
        });
    };

    renderSharedSystems = () => {
        return <table className="w3-table-all">
            <thead>
            <tr className="vl-theme">
                <th>Index</th>
                <th>Owner</th>
                <th>Name</th>
            </tr>
            </thead>
            <tbody>
            {this.state.sharedSystems.map(this.renderSharedSystem)}
            </tbody>
        </table>;
    };

    renderSharedSystem = (s) => {
        return <tr>
            <td>{s.index}</td>
            <td>{s.owner}</td>
            <td>{s.camera_name}</td>
        </tr>;
    };

    renderAddSystem = () => {
        return <VLButton title="Add System" className="top8" href={"/system/" + this.props.id}/>;
    };

}

const UserListRow = ({user, customerID}) => (
    <tr>
        <td>{user.username}</td>
        <td>{user.email}</td>
        <td>{Util.getAccessLevel(user.access_level)}</td>
        <td><VLButton href={"/customers/" + customerID + "/users/" + user.id} title="Manage"/>
        </td>
    </tr>
);

const UsersList = ({users, customerID}) => (
    <table className="w3-table-all">
        <thead>
        <tr className="vl-theme">
            <th>Name</th>
            <th>Email</th>
            <th>Access Level</th>
            <th/>
        </tr>
        </thead>
        <tbody>
        {users.map(user => <UserListRow user={user} customerID={customerID}/>)}
        </tbody>
    </table>
);

const GroupsList = ({groups, onUpdate}) => {

    const [names, setNames] = useState([]);

    useEffect(() => {
        setNames(groups);
    }, [groups]);

    const nameUpdater = index => event => {
        const newNames = [...names];
        newNames[index] = event.target.value;
        setNames(newNames);
    };

    const handleSave = () => {
        onUpdate(names);
    };

    return (
        <div>
            <table className="w3-table-all">
                <thead>
                <tr className="vl-theme">
                    <th>Group Name</th>
                </tr>
                </thead>
                <tbody>
                {names.map((name, index) => <tr>
                    <td><TextField className="wide" onChange={nameUpdater(index)} value={name} variant="outlined"/></td>
                </tr>)}
                </tbody>
            </table>
            <VLButton className="top8" title="Save" onClick={handleSave}/>
        </div>
    );
};

const SortableSystemList = ({systems, customerID}) => {
    const columns = [
        {name: "ID", field: "camera_id"},
        {name: "name", field: "camera_name"},
        {name: "Comms"},
        {name: "Number"},
        {name: "SIM Serial"},
        {name: ""}
    ];

    const data = systems.map(e => ({...e, camera_id: parseInt(e.camera_id)}));

    return <ManagedSortableTable columns={columns} data={data} initialSort="camera_id" makeRow={e => <SystemListItem system={e} customerID={customerID}/>}/>;
};

const SystemListItem = ({system, customerID}) => (
    <tr>
        <td>{system.camera_id}</td>
        <td>{system.camera_name}</td>
        <td>{system.camera_comms}</td>
        <td>{system.camera_number}</td>
        <td>{system.sim_serial}</td>
        <td><VLButton title="Manage" href={`/customers/${customerID}/systems/${system.camera_id}`}/></td>
    </tr>
);