import React, {useState} from "react";
import * as Util from "../util";

export const SortableTable = ({sort, order, setSort, setOrder, columns, children}) => {

    const renderSortableColumn = (name, field) => {
        let arrow = order ? "⬆" : "⬇";
        arrow = (sort === field) ? arrow : "";
        return <th className="hoverable noselect" onClick={() => colClicked(field)}>{name} {arrow}</th>;
    };

    const colClicked = (field) => {
        if (sort === field) {
            setOrder(!order);
        } else {
            setSort(field);
        }
    };

    return <table className="w3-table-all">
        <thead>
        <tr className="vl-theme">
            {columns.map(e => {
                if (e.field) {
                    return renderSortableColumn(e.name, e.field);
                } else {
                    return <th>{e.name}</th>;
                }
            })}
        </tr>
        </thead>
        <tbody>
        {children}
        </tbody>
    </table>;
};

export const ManagedSortableTable = ({columns, data, makeRow, initialSort}) => {
    const [sort, setSort] = useState(initialSort);
    const [order, setOrder] = useState("true");

    const sorted = (data ?? []).sort((a, b) => {
        if (order) {
            return Util.compare(a[sort], b[sort]);
        }
        return Util.compare(b[sort], a[sort]);
    });

    return (
        <SortableTable columns={columns} sort={sort} setSort={setSort} order={order} setOrder={setOrder}>
            {sorted.map(makeRow)}
        </SortableTable>
    );
};