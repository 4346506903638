import React from "react";

import VLScreen from "./VLScreen";
import FormView from "../components/FormView";
import Paper from "@material-ui/core/es/Paper/Paper";
import * as API from "../api";

export default class AddSystemScreen extends VLScreen {

    constructor() {
        super();
        this.state = {loaded: true, error: null, camera_directory: 'VL_Processed_Image', system_type: 1, outstation_type: 0}
    }

    componentDidMount() {
        API.adminPOST("customers/getNextCameraID", {customer_id: this.props.customerID}, result => {
            this.setState({camera_id: result.result.camera_id});
        })
    }

    content() {
        return (
            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <Paper style={{width: '25cm', padding: 16, borderRadius: 16}}>

                    {this.renderAddSystem()}

                </Paper>
            </div>
        );
    }

    renderAddSystem = () => {
        let systemTypes = [{id: 1, label: "Camera (1)"}, {id: 2, label: "Outstation (2)"}];
        let outstationTypes = [
            {id: 0, label: "Camera (0)"},
            {id: 1, label: "LoRa (1)"},
            {id: 2, label: "Level Sensor (2)"},
            {id: 3, label: "Hi Res Sensor (3)"}
        ];
        let items = [
            {type: "title", text: "Add System for customer " + this.props.customerID},
            {id: "camera_id", name: "Camera Id", value: this.state.camera_id, type: "text", inputType: "number"},
            {
                id: "system_type",
                name: "System Type",
                value: this.state.system_type,
                type: "dropdown2",
                options: systemTypes
            },
            {
                id: "outstation_type",
                name: "Outstation Type",
                value: this.state.outstation_type,
                type: "dropdown2",
                options: outstationTypes
            },
            {id: "camera_name", name: "Camera Name", value: this.state.camera_name, type: "text"},
            {
                id: "community_system_viewer",
                name: "Community Enabled",
                value: this.state.community_system_viewer,
                type: "bool"
            },
            {id: "camera_lat", name: "Camera Lat", value: this.state.camera_lat, type: "text"},
            {id: "camera_long", name: "Camera Lng", value: this.state.camera_long, type: "text"},
            {id: "camera_postcode", name: "Postcode", value: this.state.camera_postcode, type: "text"},
            {id: "grid_ref", name: "Grid Ref", value: this.state.grid_ref, type: "text"},
            {id: "asset_id", name: "Asset Id", value: this.state.asset_id, type: "text"},
            {id: "camera_number", name: "Number", value: this.state.camera_number, type: "text"},
            {id: "camera_directory", name: "Directory", value: this.state.camera_directory, type: "text"},
            {
                id: "camera_latest_picture",
                name: "Latest Picture A",
                value: this.state.camera_latest_picture,
                type: "text"
            },
            {
                id: "camera_latest_picture_b",
                name: "Latest Picture B",
                value: this.state.camera_latest_picture_b,
                type: "text"
            },
            {id: "custom_logo", name: "Custom Logo", value: this.state.custom_logo, type: "bool"},
            {id: "custom_logo_name", name: "Custom Logo Name", value: this.state.custom_logo_name, type: "text"},
            {id: "camera_email_add", name: "Email", value: this.state.camera_email_add, type: "text"},
            {id: "camera_email_name", name: "Email Name", value: this.state.camera_email_name, type: "text"},
            {id: "car_reg_decode", name: "Car Reg Decode", value: this.state.car_reg_decode, type: "bool"},
            {id: "car_reg_blur", name: "Car Reg Blur", value: this.state.car_reg_blur, type: "bool"},
            {id: "image_auto_blur", name: "Image auto blur", value: this.state.image_auto_blur, type: "bool"},
            {type: "button", text: "Save", onClick: this.save}

        ];

        return <FormView questionWidth={200} items={items} changed={this.formInputChanged}/>
    };

    formInputChanged = (name, value) => {
        if (value === true)
            value = 1;
        if (value === false)
            value = 0;
        this.setState({[name]: value});
    };

    save = () => {
        let data = this.state;
        data.customer_id = this.props.customerID;

        API.adminPOST("systems/addSystem", data, result => {
            if (result.success) {
                alert("Success");
                window.history.back();
            } else {
                alert(result.error);
            }
        });
    }

}