import Paper from "@material-ui/core/es/Paper/Paper";
import * as moment from "moment";
import React, {useEffect, useState} from "react";
import * as API from "../api";
import FormView, {DateInput} from "../components/FormView";
import {useAlert} from "../components/hooks";
import VLButton from "../components/VLButton";
import {useAdminApi, useApi, VLScreen2} from "./VLScreen2";

const PaperCard = ({children, style}) => (
    <Paper style={{
        borderRadius: 16,
        width: "20cm",
        maxWidth: "90vw",
        overflow: "hidden",
        ...style
    }}>
        {children}
    </Paper>
);

const EditSystemScreen = ({system, customer, customerID, onChange, onSave, deleteOldImages}) => {

    const [imageDeleteDate, setImageDeleteDate] = useState(Date.now() / 1000);

    const handleDeleteOldImages = () => deleteOldImages(imageDeleteDate);

    const onFieldUpdate = (key, value) => {
        const newSystem = {...system};
        if (key.startsWith("ptz_tag_")) {
            const id = parseInt(key.substring("ptz_tag_".length));
            const labels = system.ptz_labels;
            const newLabels = [...labels];
            newLabels[id] = value;
            newSystem.ptz_labels = newLabels;
        } else {
            newSystem[key] = value;
        }
        onChange(newSystem);
    };

    const copySimSerialToLock = () => onChange({...system, sim_serial_lock: system.sim_serial});

    const copyGsmSerialToLock = () => onChange({...system, gsm_serial_lock: system.gsm_serial});

    const ptzItems = system.ptz_labels.map((e, i) => ({
        type: "text",
        name: "PTZ " + (i + 1),
        value: e,
        id: "ptz_tag_" + i
    }));

    const items = [
        {type: "title", text: "Edit System"},
        {type: "text", name: "Name", value: system.sensor_name, id: "sensor_name"},
        {type: "text", name: "GSM Number", value: system.camera_number, id: "camera_number"},
        {type: "date", name: "Install Date", value: system.installed, id: "installed"},
        {type: "text", name: "Sim serial", value: system.sim_serial, id: "sim_serial"},
        {type: "text", name: "Sim serial lock", value: system.sim_serial_lock, id: "sim_serial_lock"},
        {type: "button", text: "Copy sim serial to lock", onClick: copySimSerialToLock, width: ""},
        {type: "text", name: "GSM serial", value: system.gsm_serial, id: "gsm_serial"},
        {type: "text", name: "GSM serial lock", value: system.gsm_serial_lock, id: "gsm_serial_lock"},
        {type: "button", text: "Copy GSM serial to lock", onClick: copyGsmSerialToLock, width: "auto"},
        ...ptzItems,
        {type: "spacer"},
        {type: "button", text: "Save", onClick: onSave}
    ];

    const images = system.sensor_images;

    return (
        <div id="content" style={{
            display: "flex",
            padding: 24,
            alignItems: "center",
            flexDirection: "column"
        }}>
            <PaperCard>
                <div style={{overflowY: "auto", height: "100%", padding: 16}}>
                    <FormView items={items} changed={onFieldUpdate}/>
                </div>
            </PaperCard>

            <PaperCard style={{marginTop: 32}}>
                <div style={{overflowY: "auto", height: "100%", padding: 16}}>
                    <h2>System Info</h2>
                    <p>Camera ID: {system.sensor_id}</p>
                    <p>Customer ID: {customerID}</p>
                    <p>Customer Pairing Code: {customer?.app_code}</p>
                    <p>Solar Panel: {system.camera_solar}V</p>
                    <p>Battery: {system.battery}V</p>
                    <p>Location: <a target="_blank" href={"https://what3words.com/" + system.what3words}>{system.what3words}</a></p>
                </div>
            </PaperCard>

            <PaperCard style={{marginTop: 32}}>
                <div style={{overflowY: "auto", height: "100%", padding: 16}}>
                    <h2>System Admin</h2>
                    <h4>Current Data</h4>
                    <p>Images: {system.image_count}</p>
                    <p>Level readings: {system.level_count}</p>
                    <h4>Delete old data</h4>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <p>Delete images / readings older than</p>
                        <DateInput onChange={setImageDeleteDate} currentValue={imageDeleteDate}/>
                        <VLButton title="Go" onClick={handleDeleteOldImages}/>
                    </div>
                </div>
            </PaperCard>

            {images.map(image => (
                <PaperCard style={{marginTop: 32}}>
                    <div style={{overflowY: "auto", height: "100%", padding: 16}}>
                        <h2>Latest Image {image.tag}</h2>
                        <h4>{image.date}</h4>
                        <img src={image.image} style={{width: "100%"}}/>
                    </div>
                </PaperCard>
            ))}

        </div>
    );
};

const Container = ({customerID, systemID}) => {
    const [loaded, error, data] = useApi("systems/list", {sensor_id: systemID, customerID}, [customerID, systemID]);

    const [, , customerData] = useAdminApi("customers/get", {customer_id: customerID}, [customerID]);

    const system = data?.sensors?.[0];

    const [editedSystem, setEditedSystem] = useState();

    const [alert, showAlert] = useAlert();

    useEffect(() => {
        if (system != null) {
            const newSystem = {...system};
            newSystem.ptz_labels = (system.ptz_info ?? []).map(e => e.tag);
            setEditedSystem(newSystem);
        }
    }, [system, setEditedSystem]);

    const onSave = () => {
        API.POST("systems/setInfoAdmin", {customerID, sensor_id: systemID, sensor: JSON.stringify(editedSystem)}, result => {
            if (result.success) {
                showAlert("Your changes have been saved", window.history.back);
            } else {
                showAlert("An error occurred");
            }
        });
    };

    const deleteOldImages = date => {
        const humanDate = moment.unix(date).format("DD/MM/YY");
        if (window.confirm("Are you sure you want to delete images and readings older than " + humanDate + "?")) {
            API.POST("systems/deleteOldData", {customerID, sensor_id: systemID, date}, result => {
                if (result.success) {
                    showAlert("Data has been purged");
                } else {
                    showAlert("An error occurred");
                }
            });
        }
    };

    return <VLScreen2 title={system?.sensor_name} loaded={loaded} error={error}>
        {alert}
        <EditSystemScreen customer={customerData.customer} system={editedSystem} customerID={customerID} onChange={setEditedSystem} onSave={onSave}
                          deleteOldImages={deleteOldImages}/>
    </VLScreen2>;
};

export default Container;