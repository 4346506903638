import React, {Component, useState, useEffect} from "react";

import VLButton from "./VLButton";
import {Switch} from "@material-ui/core";
import * as moment from "moment";

const MoneyInput = ({currentValue, onChange}) => {

    const calc = (pence) => {
        let leftOverPence = parseInt(pence) % 100;
        let leftOverPounds = (parseInt(pence) - leftOverPence) / 100;

        if (isNaN(leftOverPence)) leftOverPence = 0;
        if (isNaN(leftOverPounds)) leftOverPounds = 0;

        return [leftOverPounds, leftOverPence];
    };

    const [leftOverPounds, leftOverPence] = calc(currentValue);
    const [pounds, setPounds] = useState(leftOverPounds);
    const [pence, setPence] = useState(leftOverPence);

    useEffect(() => {
        const [leftOverPounds, leftOverPence] = calc(currentValue);

        setPounds(leftOverPounds);
        setPence(leftOverPence);
    }, [currentValue]);

    const handleChange = (e) => {
        const {name, value} = e.target;

        let localPounds = pounds;
        let localPence = pence;
        if (name === "pounds") {
            //setPounds(value);
            localPounds = value;
        } else {
            //setPence(value);
            localPence = value;
        }

        localPounds = isNaN(parseInt(localPounds)) ? 0 : parseInt(localPounds);
        localPence = isNaN(parseInt(localPence)) ? 0 : parseInt(localPence);

        onChange(localPounds * 100 + localPence);
    };

    const inputStyle = {width: 50};

    return (
        <div style={{display: 'flex'}}>
            <p>£</p>
            <input className="vl-input" value={pounds} name="pounds" style={inputStyle}
                   placeholder="0" onChange={handleChange}/>
            <p>.</p>
            <input className="vl-input" value={pence} name="pence"
                   maxLength={2} style={inputStyle}
                   placeholder="00" onChange={handleChange}/>
        </div>
    );

};

export const DateInput = ({currentValue, onChange}) => {
    const currentHuman = moment.unix(currentValue).format("YYYY-MM-DD");

    const handleChange = e => {
        const newHuman = e.target.value;
        const newUnix = moment(newHuman, "YYYY-MM-DD").unix();
        onChange(newUnix);
    };

    return (
        <div style={{display: 'flex'}}>

            <input className="vl-input" value={currentHuman} onChange={handleChange} type="date"/>
        </div>
    );
};

class FormView extends Component {

    render() {
        return (
            <form autoComplete="new-password" style={{width: '100%'}}>
                {this.renderForm()}
            </form>
        );
    }

    renderForm = () => {
        return this.props.items.map(i => <div style={{margin: 8, width: '100%'}}>{this.renderItem(i)}</div>);
    };

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        if (this.props.changed != null)
            this.props.changed(name, value);
        if (this.props.changeEvent != null)
            this.props.changeEvent(event);
    };

    renderItem = (item) => {
        if (item.type == "title") {
            return <h2>{item.text}</h2>
        }
        if (item.type == "spacer") {
            return <hr/>
        }
        if (item.type == "button") {
            let st = {width: item.width ?? '100%'};
            if (item.color) {
                st.background = item.color;
                return <VLButton disabled={true} title={item.text} style={st} onClick={item.onClick} color={item.color}/>
            }
            return <VLButton title={item.text} style={st} onClick={item.onClick} color={item.color}/>
        }
        if (item.type == "bool") {
            return (
                <div style={{display: 'flex'}}>
                    <p style={this.styles.question}>{item.name}</p>
                    <Switch name={item.id} checked={item.value} onChange={this.handleChange}/>
                </div>
            )
        }
        if (item.type == "text") {
            return (
                <div style={{display: 'flex'}}>
                    <p style={this.styles.question}>{item.name}</p>
                    <input autoComplete="new-password" style={this.styles.answer} className="vl-input" value={item.value} name={item.id}
                           readOnly={item.readonly}
                           placeholder={item.placeholder} onChange={this.handleChange}/>
                </div>
            )
        }
        if (item.type == "longtext") {
            return (
                <div style={{display: 'flex'}}>
                    <p style={this.styles.question}>{item.name}</p>
                    <textarea style={{...this.styles.answer, height: 400, resize: 'none'}} className="vl-input" value={item.value} name={item.id}
                              readOnly={item.readonly}
                              placeholder={item.placeholder} onChange={this.handleChange}/>
                </div>
            )
        }
        if (item.type == "dropdown") {
            return (
                <div style={{display: 'flex'}}>
                    <p style={this.styles.question}>{item.name}</p>
                    <select style={this.styles.answer} value={item.value} name={item.id} onChange={this.handleChange}>
                        {item.options.map((opt, i) => <option value={i}>{opt}</option>)}
                    </select>
                </div>
            )
        }
        if (item.type == "dropdown2") {
            return (
                <div style={{display: 'flex'}}>
                    <p style={this.styles.question}>{item.name}</p>
                    <select style={this.styles.answer} value={item.value} name={item.id} onChange={this.handleChange}>
                        {item.options.map((opt, i) => <option value={opt.id}>{opt.label}</option>)}
                    </select>
                </div>
            )
        }
        if (item.type == "info") {
            return (
                <div style={{display: 'flex'}}>
                    <p style={this.styles.question}>{item.name}</p>
                    <p style={this.styles.answer} className="">{item.text}</p>
                </div>
            )
        }
        if (item.type == "money") {
            return (
                <div style={{display: 'flex'}}>
                    <p style={this.styles.question}>{item.name}</p>
                    <MoneyInput currentValue={item.value} onChange={value => {
                        const name = item.id;
                        const event = {target: {name, value}};
                        this.handleChange(event)
                    }}/>
                </div>
            )
        }
        if (item.type == "date") {
            return (
                <div style={{display: 'flex'}}>
                    <p style={this.styles.question}>{item.name}</p>
                    <DateInput currentValue={item.value} onChange={value => {
                        const name = item.id;
                        const event = {target: {name, value}};
                        this.handleChange(event)
                    }}/>
                </div>
            )
        }
    };

    getQuery = () => {
        return this.props.items.map(i => {
            let question = i.id;
            let answer = this.state["answer" + i.id];
            return {question, answer};
        })
    };

    styles = {
        question: {
            width: this.props.questionWidth || 140
        },
        answer: {
            flex: 1,
        }
    }

}


export default FormView;
