import React, {Component} from "react";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-component";
import * as Util from "../util";

class ActionBar extends Component {

    constructor() {
        super();
        this.state = {menuOpen: false};
    }

    render() {
        return (
            <div>
                <AppBar position="static" style={{background: window.primary}}>
                    <Toolbar style={{color: 'white'}} color="primary">
                        {this.backButtons()}
                        <h2 style={{marginLeft: 16}}>{this.props.title}</h2>
                        <div style={{flex: '1'}}/>

                        {this.rhsButtons()}

                    </Toolbar>
                </AppBar>

                {this.state.menuOpen &&
                <div style={{
                    position: 'absolute',
                    background: '#EEE',
                    left: 0,
                    top: 64,
                    bottom: 0,
                    width: 400,
                    zIndex: 1000,
                    boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.2)'
                }}>
                    {this.renderButton("Manage Users", "/users")}
                    {this.renderButton("Usage and Logs", "/log")}
                    {this.renderButton("Matrix Views", "/matrices")}
                    {this.renderButton("Account Info", "/account")}
                    {this.renderButton("Camera Stats", "/imageCounts")}
                    {this.renderButton("GSM / IP Networks", "/gsmNetwork")}
                    {this.renderButton("Settings", "/settings")}
                    {this.renderButton("About", "/about")}
                </div>}
            </div>
        );
    }

    getLinks = () => {
        let def = (Util.isSubAccount() || Util.isCommunity()) ? [{title: "Home", link: "/matrix/0"}] : [{
            title: "List",
            link: "/list/0"
        }, {title: "Matrix", link: "/matrix/0"}];
        return Util.getHistory().concat(def);
    };

    navLinks = () => {
        return this.getLinks().map(l => {
            return <Link href={l.link}>{l.title}</Link>
        })
    };

    backButtons = () => {
        if (this.props.hideBack || this.props.hideAll) {
            if (this.props.showMenu) {
                return <i onClick={() => this.setState({menuOpen: !this.state.menuOpen})} style={{fontSize: 32}}
                          className="material-icons hoverable noselect">menu</i>;
            }
            return;
        }
        return [<Button onClick={this.goBack} style={{color: 'white'}}>
            <i style={{fontSize: 48}} className="material-icons">arrow_back</i>
        </Button>,
            <Button component={Link} href={this.homeUrl()} style={{color: 'white'}}>
                <i style={{fontSize: 48}} className="material-icons">home</i>
            </Button>
        ]
    };

    rhsButtons = () => {
        if (this.props.hideAll)
            return;
        if (Util.isCommunity()) {
            return [
                <p>{Util.getAppName()}</p>,
                <button className="w3-button w3-white" onClick={Util.unpair}>Unpair</button>
            ]
        }
        return [
            <p>{Util.getUsername()}</p>,
            <i className="material-icons hoverable" title="Logout" style={{fontSize: 36, marginLeft: 16}}
               onClick={Util.logout}>logout</i>
        ]
    };

    goBack = () => {
        if (document.referrer.indexOf("vision-link.co.uk") == -1 && document.referrer.indexOf("localhost") == -1) {
            window.location.replace(this.homeUrl());
        }
        else {
            window.history.back();
        }
    };

    renderButton = (title, link) => {
        return <Link className="menuItem" style={{textDecoration: 'none'}} href={link}>
            <div style={{borderBottom: '1px solid black', padding: 16}}><p className="wide">{title}</p></div>
        </Link>
    };

    homeUrl = () => {
        return (Util.isSubAccount() || Util.isCommunity()) ? "/matrix/0" : "/";
    }

}


export default ActionBar;
