import React, {Component} from "react";

import {Button} from '@material-ui/core';
import {Link} from "react-router-component";

class VLButton extends Component {

    render() {
        if (this.props.href != null)
            return (
                <Button
                    variant="contained"
                    color={this.props.disabled ? "default" : 'primary'}
                    className={"vl-button " + this.props.className}
                    component={Link}
                    style={this.props.style}
                    target={this.props.target || ''}
                    href={this.props.href}>
                    {this.props.title}
                </Button>
            );
        return (
            <Button
                variant="contained"
                color={this.props.disabled ? "default" : 'primary'}
                className={"vl-button " + this.props.className}
                style={this.props.style}
                target={this.props.target || ''}
                onClick={this.props.onClick}>
                {this.props.title}
            </Button>
        );
    }
}


export default VLButton;
