import {createMuiTheme} from '@material-ui/core/styles';
import red from "@material-ui/core/es/colors/red";

const vl = {
    main: window.primary
};

export default createMuiTheme({
    palette: {
        primary: vl,
        secondary: vl,
        textColor: red,
        contrastText: red,
        accent1Color: red,
        alternateTextColor: red,
        red: red
    }
});