import React from "react";
import {useEffect, useState} from "react";

export const ApiChecker = () => {

    const [data, setData] = useState(null);

    useEffect(() => {
        let request = new XMLHttpRequest();
        request.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 200) {
                let json = JSON.parse(this.responseText);
                setData(json);
            }
        };

        request.open("POST", "https://api.vision-link.co.uk/admin/api/apitest.php", true);
        request.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        request.send();
    }, []);

    if (data == null) return <p>Checking...</p>;

    let problem = data.problem;
    let status = problem ? <span style={{color: 'red'}}>PROBLEM</span> : <span style={{color: 'green'}}>HEALTHY</span>;

    return (
        <>
        <h3>{status}</h3>
        <p style={{whiteSpace: 'pre-wrap'}}>{data.text}</p>
        </>
    );
};

export default ApiChecker;