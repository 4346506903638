import React, {Component, useState} from "react";

import * as API from "../api"
import * as Util from "../util";
import FormView from "../components/FormView";
import Paper from "@material-ui/core/es/Paper/Paper";
import {useApi, VLScreen2} from "./VLScreen2";
import {useAlert} from "../components/hooks";

const UserScreen = ({originalAccess, user, onSave, onDelete, onUpdate, creating}) => {

    if(user == null) return null;

    const levels = ["Admin", "User", "Guest"];
    const items = [
        {type: "title", text: "User"},
        {type: "text", name: "Name", value: user.username, id: 'username'},
        {type: "text", name: "Email", value: user.email, id: 'email'},
        {
            type: "text",
            name: "Password",
            value: user.password,
            placeholder: creating ? "" : "Leave blank to keep current password",
            id: 'new_password'
        },
        {
            type: "dropdown",
            name: "Access Level",
            value: user.access_level,
            options: levels,
            id: 'access_level'
        },
        //{
        //    type: "bool",
        //    name: "Receive people/vehicle image alerts",
        //    value: user.image_push,
        //    id: 'image_push'
        //},
        {type: "button", text: "Save", onClick: onSave}
    ];

    if (!creating)
        items.push({type: "button", text: "Delete User", onClick: onDelete, color: 'red'});

    return (
        <div id="content" style={{
            display: "flex",
            padding: 24,
            justifyContent: 'center'
        }}>
            <Paper style={{
                borderRadius: 16,
                width: '20cm',
                maxWidth: '90vw',
                maxHeight: 'calc(100vh - 116px)',
                overflow: 'hidden'
            }}>
                <div style={{overflowY: 'auto', height: '100%', padding: 16}}>
                    <FormView questionWidth={180} items={items} changed={onUpdate}/>
                    {(originalAccess < user.access_level) &&
                    <p style={{color: 'red', textAlign: 'center', marginTop: 16}}>Warning: You are reducing the access level of this user</p>
                    }
                </div>
            </Paper>
        </div>
    )
};

const Container = ({customerID, userID}) => {
    const [loaded, error, data, reload] = useApi("users/get", {customerID, id: userID}, [userID]);

    const [user, setUser] = React.useState(null);
    const [creating, setCreating] = React.useState(true);
    const [originalAccess, setOriginalAccess] = useState(5);

    const [alertComponent, showAlert] = useAlert();

    React.useEffect(() => {
        if(!loaded) return;
        if (data.user === null || userID === "-1") {
            setUser({image_push: false, username: '', email: '', password: '', access_level: 2});
            setCreating(true);
        } else {
            const newUser = data.user;
            newUser.access_level = newUser.access_level - 2;
            setUser(newUser);
            setCreating(false);
            setOriginalAccess(newUser.access_level);
        }
    }, [data, loaded]);

    const save = () => {
        const params = {...user, user_id: userID, customerID};

        const verb = creating ? "added" : "edited";
        const api = creating ? "add" : "edit";
        API.POST(`users/${api}`, params, result => {
            if (result.success) {
                showAlert(`User has been ${verb}`, Util.goBack);
            }
            else {
                showAlert(result.error);
            }
        });
    };

    const deleteUser = () => {
        if (window.confirm("Are you sure you want to delete this user?")) {
            API.POST("users/delete", {id: userID, customerID}, result => {
                if (result.success) {
                    showAlert("User has been deleted", Util.goBack);
                }
                else {
                    showAlert(result.error);
                }
            });
        }
    };

    const onUpdate = (key, value) => {
        if(key === "new_password")
            key = "password";
        const newUser = {...user};
        newUser[key] = value;
        setUser(newUser);
    };

    return (
        <VLScreen2 title={creating ? "Add User" : "Edit User"} error={error} loaded={loaded} retry={reload}>
            {alertComponent}
            <UserScreen originalAccess={originalAccess} user={user} onDelete={deleteUser} onSave={save} onUpdate={onUpdate} creating={creating}/>
        </VLScreen2>
    );
};

export default Container;