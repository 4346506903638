import React from 'react'
import VLComponent from "./VLComponent";
import ActionBar from "../components/ActionBar";
import VLButton from "../components/VLButton";
import Paper from "@material-ui/core/es/Paper/Paper";
import Loader from "../components/Loader";

export default class VLScreen extends VLComponent {

    constructor() {
        super();
        this.state = {error: false, loaded: false}
    }

    render() {
        return <div>
            <ActionBar title={this.title()} hideBack={this.state.hideBack === true} showMenu={this.state.showMenu}/>
            <div id="content" style={{
                display: (this.state.loaded && this.state.error == null ) ? "block" : 'none',
                maxHeight: 'calc(100vh - 64px)',
                padding: 24,
                overflowY: 'auto'
            }}>
                {this.content()}
            </div>
            {this.pageContent()}
        </div>
    }

    pageContent() {

        return [<Loader loaded={this.state.loaded}/>,
            <div style={{
                display: (this.state.error != null && this.state.loaded) ? 'flex' : 'none',
                justifyContent: 'center',
                width: '100%',
                marginTop: 64
            }}>
                <Paper style={{padding: 64, textAlign: 'center', borderRadius: 16}}>
                    <h2>Error</h2>
                    <p>{this.state.error}</p>
                    <VLButton title="Retry" onClick={this.load} class="top8" style={{marginTop: 16, width: 400}}/>
                </Paper>
            </div>
        ]
    }

    title() {
        return "Vision Link Admin Panel";
    }

}