import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import * as moment from "moment";
import React, {useState} from "react";
import * as API from "../api";
import ApiChecker from "../components/ApiChecker";
import {ManagedSortableTable} from "../components/SortableTable";

import VLButton from "../components/VLButton";
import * as Util from "../util";
import VLScreen from "./VLScreen";

export default class HomeScreen extends VLScreen {

    constructor() {
        super();
        this.state = {loaded: false, customers: [], error: null, hideBack: true, sort: "customer_id", order: true};
    }

    componentDidMount() {
        API.adminPOST("customers/list", {}, result => {
            Util.handleLogout(result);
            result.result.customers = result.result.customers.map(e => {
                e.customer_id = parseInt(e.customer_id);
                e.pairing_code = parseInt(e.pairing_code);
                return e;
            });
            this.setState(result.result);
            this.setState({loaded: true});
        });
        API.adminPOST("systems/list", {}, result => {
            Util.handleLogout(result);
            const systems = result.result.systems.map(e => {
                e.customer_id = parseInt(e.customer_id);
                e.camera_id = parseInt(e.camera_id);
                return e;
            });
            this.setState({systems});
        });
        API.adminPOST("users/list", {}, result => {
            Util.handleLogout(result);
            const users = result.result.users.map(e => ({...e, access_level: parseInt(e.access_level), nameLower: e.username.toLowerCase()}));
            this.setState({users});
        });
    }

    content() {
        return (
            <div>
                <CollapsibleSection title="API Status">
                    <div className="vl-border"><ApiChecker/></div>
                </CollapsibleSection>
                <CollapsibleSection title="Add Customer">
                    {this.addCustomerForm()}
                </CollapsibleSection>
                <CollapsibleSection title="All Customers">
                    <CustomersTable customers={this.state.customers}/>
                </CollapsibleSection>
                <CollapsibleSection title="All Systems">
                    <SortableSystemsTable systems={this.state.systems}/>
                </CollapsibleSection>
                <CollapsibleSection title="All Users">
                    <SortableUsersTable users={this.state.users}/>
                </CollapsibleSection>
            </div>
        );
    }

    addCustomerForm = () => {

        return <form className="vl-border" action={API.baseURL + "customers/add"} method="post" encType="multipart/form-data">
            <div className="form-row">
                <p>App Name</p>
                <input name="app_name" type="text" className="vl-input"/>
            </div>

            <div className="form-row">
                <p>App Logo</p>
                <input type="hidden" name="MAX_FILE_SIZE" value="3000000"/>
                <input name="app_image" type="file" accept="image/png, image/jpeg" size="3000000"/>
            </div>

            <div className="form-row">
                <p>Is EA</p>
                <input name="is_ea" type="checkbox"/>
            </div>

            <div className="form-row">
                <p>Group 1</p>
                <input name="group1" type="text" className="vl-input"/>
            </div>

            <div className="form-row">
                <p>Group 2</p>
                <input name="group2" type="text" className="vl-input"/>
            </div>

            <div className="form-row">
                <p>Group 3</p>
                <input name="group3" type="text" className="vl-input"/>
            </div>

            <div className="form-row">
                <p>Community Viewer</p>
                <input name="community_en" type="checkbox"/>
            </div>
            <br/>

            <input name="token" value={Util.getJwt()} readOnly hidden/>
            <input type="submit" className="w3-button vl-theme" value="Add Customer"/>
        </form>;
    };

}

const CustomerRow = ({c}) => {
    let lastC = c.lastCommunityLogin ? moment.unix(c.lastCommunityLogin).format("DD/MM/YY HH:mm") : "Never";
    let lastS = c.lastSystemLogin ? moment.unix(c.lastSystemLogin).format("DD/MM/YY HH:mm") : "Never";

    let loginsC = lastC + " (" + c.communityLogins + " in last 24h)";
    let loginsS = lastS + " (" + c.systemLogins + " in last 24h)";

    return <tr>
        <td>{c.customer_id}</td>
        <td>{c.pairing_code}</td>
        <td>{c.app_name}</td>
        <td>{c.online}/{c.systems}</td>
        <td><a href={c.image_url}>{c.app_image}</a></td>
        <td>{loginsC}</td>
        <td>{loginsS}</td>
        <td><VLButton href={"/customers/" + c.customer_id} title="Manage"/></td>
    </tr>;
};

const CustomersTable = ({customers}) => {
    const columns = [
        {name: "Customer ID", field: "customer_id"},
        {name: "Pairing Code", field: "pairing_code"},
        {name: "Customer Name", field: "app_name"},
        {name: "Systems"},
        {name: "Image"},
        {name: "Used (Community)"},
        {name: "Used (System Viewer)"},
        {name: ""}
    ];

    return <ManagedSortableTable columns={columns} data={customers} initialSort={"customer_id"} makeRow={e => <CustomerRow c={e}/>}/>;
};

const SystemsTable = ({systems}) => {
    const columns = [
        {name: "Customer ID", field: "customer_id"},
        {name: "Camera ID", field: "camera_id"},
        {name: "Camera Name", field: "camera_name"},
        {name: "SIM Serial"},
        {name: "Number"},
        {name: ""}
    ];

    return <ManagedSortableTable columns={columns} data={systems} initialSort={"customer_id"} makeRow={e => <SystemRow system={e}/>}/>;
};

const SystemRow = ({system}) => {
    return <tr>
        <td>{system.customer_id}</td>
        <td>{system.camera_id}</td>
        <td>{system.camera_name}</td>
        <td>{system.sim_serial}</td>
        <td>{system.camera_number}</td>
        <td><VLButton href={"/customers/" + system.customer_id + "/systems/" + system.camera_id} title="Manage"/></td>
    </tr>;
};

const SortableSystemsTable = ({systems}) => {
    const [search, setSearch] = useState("");

    const query = search.trim().toLowerCase();
    const filtered = (systems ?? []).filter(system => {
        if (query === "") return true;
        return system.camera_id == query
            || (system.camera_name && system.camera_name.toLowerCase().indexOf(query) !== -1)
            || (system.camera_number && system.camera_number.indexOf(query) !== -1)
            || (system.sim_serial && system.sim_serial.toLowerCase().indexOf(query) !== -1);
    });

    return (
        <div>
            <TextField label="Search" className="wide" style={{marginBottom: 16}} variant="outlined" value={search} onChange={e => setSearch(e.target.value)}/>
            <SystemsTable systems={filtered}/>
        </div>
    );
};

const access_levels = ["", "Super User", "Admin", "User", "Guest"];

const UserRow = ({user}) => (
    <tr>
        <td>{user.app_name} ({user.customerID})</td>
        <td>{user.username}</td>
        <td>{user.email}</td>
        <td>{access_levels[user.access_level]}</td>
        <td><VLButton href={`/customers/${user.customerID}/users/${user.id}`} title="Manage"/></td>
    </tr>
);

const SortableUsersTable = ({users}) => {
    const columns = [
        {name: "Customer ID", field: "app_name"},
        {name: "Username", field: "nameLower"},
        {name: "Email", field: "email"},
        {name: "Access Level", field: "access_level"},
        {name: ""}
    ];

    return <ManagedSortableTable columns={columns} data={users} initialSort={"username"} makeRow={e => <UserRow user={e}/>}/>;
};

const CollapsibleSection = ({title, children}) => {
    const [open, setOpen] = useState(false);

    return (
        <div>
            <div style={{display: "flex", alignItems: "center"}}>
                <h2 style={{flex: 1}}>{title}</h2>
                <Button variant="outlined" color="primary" onClick={() => setOpen(!open)}>{open ? "Hide" : "Show"}</Button>
            </div>
            {open && children}
        </div>
    );
};