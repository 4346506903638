import React, {Component} from 'react';
import './App.css';
import './css/w3.css';
import './css/pikaday.css';
import theme from "./Theme";

import {ThemeProvider as MuiThemeProvider} from "@material-ui/core";

import {Location, Locations, NotFound} from "react-router-component";
import LoginScreen from "./views/LoginScreen";
import ErrorScreen from "./views/ErrorScreen";
import HomeScreen from "./views/HomeScreen";
import CustomerScreen from "./views/CustomerScreen";
import AddSystemScreen from "./views/AddSystemScreen";
import UserScreen from "./views/UserScreen";
import EditSystemScreen from "./views/EditSystemScreen";

class App extends Component {

    constructor() {
        super();
        this.routerRef = React.createRef();
    }

    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <div style={{width: "100vw", height: "100vh", overflowY: "hidden", overflowX: "hidden"}} className="bg">
                    <Locations ref={this.routerRef}>
                        <Location path="/" handler={HomeScreen}/>
                        <Location path="/login" handler={LoginScreen}/>
                        <Location path="/customers/:id" handler={CustomerScreen}/>
                        <Location path="/system/:customerID" handler={AddSystemScreen}/>
                        <Location path="/customers/:customerID/users/:userID" handler={UserScreen}/>
                        <Location path="/customers/:customerID/systems/:systemID" handler={EditSystemScreen}/>
                        <NotFound handler={ErrorScreen}/>
                    </Locations>
                </div>
            </MuiThemeProvider>
        );
    }

    navigate = link => {
        this.routerRef.current.navigate(link);
    }
}

export default App;
