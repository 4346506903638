import React, {Component} from "react";
import AlertDialog from "../components/AlertDialog";

class VLComponent extends Component {

    handleInputChange = (event, cb) => {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        if (typeof cb !== "function") {
            cb = null;
        }

        this.setState({
            [name]: value
        }, cb);
    };

    handleNumberInputChange = (event, cb) => {
        const target = event.target;
        let value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        if (typeof cb !== "function") {
            cb = null;
        }

        value = value.replace(/[^0-9]/g, "");

        this.setState({
            [name]: value
        }, cb);
    };

    textAlertContent = () => {
        return <p style={{textAlign: 'center'}}>{this.state.textAlertMessage}</p>;
    };

    renderTextAlert = () => {
        return <AlertDialog title={this.state.textAlertTitle} content={this.textAlertContent}
                            positive={this.state.textAlertPositive} negative={this.state.textAlertNegative}
                            action={this.textAlertAction} open={this.state.showingTextAlert || false}/>
    };

    textAlertAction = (a) => {
        if (a) {
            if (this.state.textAlertOnClick != null)
                this.state.textAlertOnClick();
        }
        this.setState({showingTextAlert: false});
    };

    basicAlert = text => {
        this.showTextAlert(text, "", "OK", "", null);
    };

    showTextAlert = (title, message, pos, negative, onClick) => {
        this.setState({
            textAlertTitle: title,
            textAlertMessage: message,
            textAlertPositive: pos || "Ok",
            textAlertNegative: negative,
            textAlertOnClick: onClick,
            showingTextAlert: true
        })
    };

    requireImage = (src, done) => {
        const img = new Image();
        img.src = src;
        img.onload = done;
        img.onError = done;
    };

    requireImages = (srcs, done) => {
        srcs = srcs.filter(i => i != null && i != "null" && i != "");
        let loaded = 0;
        let total = srcs.length;
        let onDone = () => {
            loaded += 1;
            if (loaded == total) {
                done();
            }
        };
        srcs.forEach(src => {
            const img = new Image();
            img.src = src;
            img.onload = onDone;
            img.onError = onDone;
        })
    };

    filterState = (keys) => {
        let n = {};
        keys.forEach(key => {
            n[key] = this.state[key];
        });
        return n;
    };

}

export default  VLComponent;